import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

export const getCompanyList = data => {
  return api({
    url: '/admin/employ/company/index',
    method: 'post',
    data
  });
};


export const softDelete = data => {
    return api({
      url: '/admin/employ/company/softDelete',
      method: 'post',
      data
    });
  };
  export const putBack = data => {
    return api({
      url: '/admin/employ/company/putBack',
      method: 'post',
      data
    });
  };
  export const del = data => {
    return api({
      url: '/admin/employ/company/del',
      method: 'post',
      data
    });
  };
  
export const getCompanyDetail = data=>{
  return api({
    url: 'admin/employ/company/detail',
    method: 'post',
    data
  });
} 
export const saveCompany = data =>{
  return api({
    url: '/admin/employ/company/save',
    method: 'post',
    data
  });
} 
export const getCategories = data =>{
  return api({
    url: '/admin/employ/company/categories',
    method: 'post',
    data
  });
} 
//会员excel模板下载
export const CompanyListExample = () => {
  return fetchFile({
    url: '/admin/employ/company/importXlsx',
    method: 'post',
    download: true,
    data: {},
  })
}
export const importCompany = (data) => {
  return api({
    url: '/admin/employ/company/import',
    method: 'post',
    data,
    notCancel: true,
  })
}
export const exportCompany = (data) => {
  return fetchFile({
    url: 'admin/employ/company/export',
    method: 'post',
    data,
    download: true,
  })
}

export const getCompanyAll = (data) => {
  return api({
    url: '/admin/employ/company/all',
    method: 'post',
    data,

  })
}


