import Tinymce from '@/base/components/Editor/Tinymce'
import MediaSelector from '@/modules/common/components/MediaSelector'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
export default {
  components: {
    Tinymce,
    ImportArticle,
    ImportHtmlCode,
    MediaSelector,
  },
  data() {
    return {
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    }
  },
  methods: {
    /**
     * 富文本编辑器自定义工具栏按钮点击事件
     * @param {Object} e
     */
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    /**
     * 逐一向富文本编辑器插入来自媒体库的图片
     * @param {Array} e 选中的图片数组
     */
    updateImages(e) {
      e.forEach((item) => {
        if (item.type !== 'image') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        if (item.type !== 'video') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                  contenteditable="false"
                  data-mce-object="video"
                  data-mce-p-controls="controls"
                  data-mce-p-controlslist="nodownload"
                  data-mce-p-allowfullscreen="true"
                  data-mce-p-frameborder="no"
                  data-mce-p-scrolling="no"
                  data-mce-html=""
                  data-mce-p-src="${item.url}">
                  <video controls src="${item.url}"></video></span></p>
                  <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        if (item.type !== 'audio') return
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
              contenteditable="false"
              data-mce-object="audio"
              data-mce-p-controls="controls"
              data-mce-p-controlslist="nodownload"
              data-mce-p-frameborder="no"
              data-mce-p-scrolling="no"
              data-mce-html=""
              data-mce-p-src="${item.url}">
              <audio controls src="${item.url}"></audio></span></p>
              <p><br/></p>`
        )
      })
    },
   /**
     * 设置导入成功的文章内容
     * 设置封面图和标题
     * @param {Object} e
     */
   getImportDetail(e) {
    this.$set(this.form, 'content', e.content)
    this.$refs.editor.$emit('change', e.content)
  },
  },
}
