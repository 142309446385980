<template>
  <el-dialog
    title="选择地址"
    :visible.sync="openDialog"
    width="650px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('addressForm')"
    append-to-body
  >
    <el-form
      v-if="addressForm"
      class="dialog-form"
      size="medium"
      :model="addressForm"
      :rules="rules"
      ref="addressForm"
    >
      <el-form-item>
        <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息，经纬度啥的 -->
        <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放，zoom是视图比例 -->
        <baidu-map
          class="bmView"
          :center="{
            lat: addressForm.lat || 39.910924,
            lng: addressForm.lng || 116.413387,
          }"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @click="getLocationPoint"
          @ready="loadMap"
        >
          <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation> -->
          <bm-local-search
            :keyword="addressForm.address_name"
            :auto-viewport="true"
            style="display: none"
            @searchcomplete="getLocation"
          >
          </bm-local-search>
          <bm-info-window
            style="position: relative"
            :position="{
              lat: addressForm.lat || 39.910924,
              lng: addressForm.lng || 116.413387,
            }"
            v-if="addressDetail"
            :show="addressDetail !== 'null'"
          >
            <div class="info-title">
              <span>{{ addressDetail.title }}</span>
              <p
                class="address-detail cursor-pointer"
                @click="checkDetail(addressDetail.detailUrl)"
                v-if="addressDetail.detailUrl"
              >
                详请
              </p>
            </div>
            <div class="info-content">
              <p>地址：{{ addressDetail.address }}</p>
              <p v-if="addressDetail.phoneNumber">
                电话：{{ addressDetail.phoneNumber }}
              </p>
              <p v-if="addressDetail.tags">
                标签：{{ addressDetail.tags.join('、') }}
              </p>
            </div>
          </bm-info-window>
        </baidu-map>
        <el-form-item
          prop="lat"
          class="search-address"
          label="地点名称："
          label-width="100px"
        >
          <el-input
            style="display: none"
            type="hidden"
            :value="addressForm.lat"
          ></el-input>
          <el-select
            :value="addressForm.address_name"
            filterable
            remote
            reserve-keyword
            placeholder="请输入地址"
            @change="handleAddressChange"
            :remote-method="searchLocation"
            style="width: 250px"
            @blur="onBlur"
            name="address_name"
          >
            <el-option
              v-for="item in addressOptions"
              :key="item.uid"
              :label="item.address"
              :value="item.uid"
            >
              <!--              :value="{title: item.title, address: item.address}"-->
              <!--              :value="item.title ? (item.title + '('+ item.address+ ')') : item.address"-->
              {{ item.title ? `${item.title}(${item.address})` : item.address }}
            </el-option>
          </el-select>
          <span
            class="copy-btn cursor-pointer"
            @click="copyAddress(addressForm.address_name)"
            >复制地点</span
          >
        </el-form-item>
        <el-form-item
          v-if="addressForm.address_name"
          prop="address"
          class="search-address"
          label="具体地址："
          label-width="100px"
        >
          <el-input
            style="width: 250px"
            type="text"
            v-model="addressForm.address"
            placeholder="请输入"
            clearable
            name="address"
          ></el-input>
          <span
            class="copy-btn cursor-pointer"
            @click="copyAddress(addressForm.address)"
            >复制地址</span
          >
        </el-form-item>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="openDialog = false">取消</el-button>
      <el-button size="medium" type="primary" @click="submit('addressForm')"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { copyText } from '@/base/utils/tool.js'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    detailForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {
        address: [
          { required: true, message: '请输入地址信息', trigger: 'blur' },
        ],
        lat: [{ required: true, message: '请输入地址信息', trigger: 'blur' }],
      },
      addressForm: {
        lat: '',
        lng: '',
        address: '',
        address_name: '',
      },
      addressDetail: null,
      zoom: 12.8, //地图缩放比例
      addressOptions: [],
    }
  },
  methods: {
    // 加载地图信息
    loadMap({ BMap, map }) {
      // this.location.lng = this.form.lng || 116.413387;
      // this.location.lat = this.form.lat || 39.910924;
    },
    //搜索地址
    searchLocation(e) {
      // this.addressForm.address = e;
      this.addressForm.address_name = e
    },
    onBlur() {
      if (this.addressForm.lat) return
      if (!this.addressOptions?.length) return
      const { lat, lng } = this.addressOptions[0].point
      this.addressForm.lat = lat
      this.addressForm.lng = lng
    },
    // 选择地址
    handleAddressChange(e) {
      // this.addressForm.address = e;
      const target = this.addressOptions.find((ad) => ad.uid === e)
      if (target) {
        // this.addressForm.lat = ''
        // this.addressForm.lng = ''
        this.addressForm.address = target.address
        this.addressForm.address_name = target.title
        this.addressForm.lat = target.point.lat
        this.addressForm.lng = target.point.lng
      }
    },
    // 获取地图信息
    getLocationPoint(e) {
      /* 创建地址解析器的实例 */
      let geoCoder = new BMap.Geocoder()

      /* 利用坐标获取地址的详细信息 */
      geoCoder.getLocation(e.point, (res) => {
        this.addressDetail = res.surroundingPois[0] || res
        // console.log(this.addressDetail, 'geo coder')
        // this.handleAddressChange(this.addressDetail)

        // console.log(this.addressDetail)
        //   geoCoder.getPoint(
        //   res.address,
        //   function (point) {
        //     if (point) {
        //       map.centerAndZoom(point, 16);
        //       map.addOverlay(
        //         new BMapGL.Marker(point, { title: res.address })
        //       );
        //     } else {
        //       alert("您选择的地址没有解析到结果！");
        //     }
        //   },
        //   "北京市"
        // );
      })
    },
    // 跳转百度地图
    checkDetail(url) {
      window.open(url, '_blank')
    },
    //
    getLocation(e) {
      this.$nextTick(() => {
        if (e) {
          let searchResult
          for (let key in e) {
            if (
              Array.isArray(e[key]) &&
              e[key].length &&
              e[key][0].point !== undefined
            ) {
              searchResult = e[key]
            }
          }
          if (searchResult) {
            this.addressOptions = searchResult
            // console.log('results', searchResult)
            // console.log('form', JSON.stringify(this.addressForm))
            const index = this.addressOptions.findIndex(
              (ad) => ad.title === this.addressForm.address_name
            )
            const index2 = this.addressOptions.findIndex(
              (ad) => ad.address === this.addressForm.address
            )
            // console.log('result index', index, index2)
            this.addressDetail =
              this.addressOptions[
                index === -1 && index2 === -1
                  ? 0
                  : index2 > index
                  ? index2
                  : index
              ]
            // console.log('detail', this.addressDetail)
            this.addressForm.lng = this.addressDetail.point.lng
            this.addressForm.lat = this.addressDetail.point.lat
            // this.addressForm.address_name = this.addressDetail.title;
          }
        }
      })
    },
    // 复制地址
    copyAddress(address) {
      if (!address) {
        this.$message.info('请输入地址')
        return
      }
      copyText(address)
        .then((res) => {
          this.$message.success('复制成功')
        })
        .catch((e) => {})
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('change', { ...this.addressForm })
          this.openDialog = false
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
    },
  },
  watch: {
    openDialog: {
      deep: true,
      handler(val) {
        if (val) {
          this.addressForm = {
            ...this.detailForm,
            address_name:
              this.detailForm.address_name || this.detailForm.address,
          }
        }
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-form-item {
    margin-bottom: 0px;

    &+.el-form-item {
      margin-top: 10px;
    }
  }
  ::v-deep.el-form-item__label {
    float: left !important;
  }
  .bmView {
    width: 610px;
    /*width: 100%;*/
    height: 350px;
    margin: 0 auto;
  }

  .info-title {
    position: absolute;
    display: flex;
    top: -10px;
    color: #cc5522;
    font-size: 14px;
    font-weight: bold;
    span {
      display: -webkit-box;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .address-detail {
      margin-left: 5px;
      font-size: 12px;
      color: #3d6dcc;
      font-weight: normal;
      text-decoration: none;
    }
  }

  .info-content {
    position: absolute;
    top: 20px;

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .search-address {
    width: 560px;
    padding: 10px 0 0;
    font-size: 12px;
    font-weight: 500;
    color: rgba(117, 117, 117, 1);
    .copy-btn {
      color: #3576ff;
      margin-left: 20px;
    }
  }
}
</style>
