const scale = [
  { label: '10人以内', value: 0 },
  { label: '10-20 人', value: 1 },
  { label: '21-50 人', value: 2 },
  { label: '51-100 人', value: 3 },
  { label: '101-300 人', value: 4 },
  { label: '301-500 人', value: 5 },
  { label: '501-1000 人', value: 6 },
  { label: '1000 人以上', value: 7 },
]
const financing = [
  { label: '不需要融资', value: 0 },
  { label: '天使轮', value: 1 },
  { label: 'A轮', value: 2 },
  { label: 'B轮', value: 3 },
  { label: 'C轮', value: 4 },
  { label: 'D轮', value: 5 },
  { label: '已上市', value: 6 },
]
const education = [

  { label: '中学或以下', value: 0 }, 
  { label: '专科', value: 1 },           
  { label: '大专', value: 2 },      
  { label: '本科', value: 3 },      
  // 产品说不要研究生的
  // { label: '研究生', value: 4 },      
  { label: '硕士', value: 5 },      
  { label: '博士', value: 6 },      

]
export { scale, financing,education }
