<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="企业 logo：">
        <image-wall
          v-model="form.logo"
          fit="contain"
          :width="120"
          :height="120"
          ratio="136:136"
        >
          <!-- <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span>建议上传尺寸比例为136px*136px,不上传图片时，默认使用系统自带图片
                </p> -->
        </image-wall>
        <el-input v-show="false" v-model="form.logo"></el-input>
      </el-form-item>
      <el-form-item label="企业名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="企业邮箱：" prop="email">
        <el-input v-model="form.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="企业行业：" prop="category">
        <el-cascader
          placeholder="请选择，可搜索"
          :value="form.category.split(',')"
          :options="categoryArr"
          @change="(val) => (form.category = val.join(','))"
          filterable
          :props="{
            checkStrictly: true,
            label: 'name',
            value: 'name',
            children: 'children',
            expandTrigger: 'hover',
          }"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="企业规模：">
        <el-select v-model="form.scale" placeholder="请选择">
          <el-option
            v-for="item in scale"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="融资情况：">
        <el-select v-model="form.financing" placeholder="请选择">
          <el-option
            v-for="item in financing"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业地址：" prop="addresses">
        <div class="tag-box">
          <el-tag
            :key="index"
            v-for="(tag, index) in form.addresses"
            closable
            :disable-transitions="false"
            @close="editAddresses(index)"
            @click="addAddresses(index)"
            class="el-icon-location-outline"
          >
            <span class="tag-text">
              <template v-if="tag.name">{{ tag.name }}(</template>
              {{ tag.address }}
              <template v-if="tag.name">)</template></span
            >
          </el-tag>
          <el-button
            class="button-new-tag"
            size="small"
            @click="addAddresses(-1)"
            >+ 添加地址</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="企业描述：" prop="content">
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="form.content"
          show-article-import
          style="max-width: 540px"
        />
      </el-form-item>
    </el-form>
    <FixedActionBar :z-index="2">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
    <AddressSelector
      v-model="addressSelector.show"
      @change="handleChangeAddress"
      :detailForm="addressSelector.form"
    />
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import AddressSelector from '@/modules/common/components/AddressSelector.vue'
import ImageWall from '@/modules/common/components/SingleMediaWall.vue'
// 接口
import {
  getCompanyDetail,
  saveCompany,
  getCategories,
} from '@/modules/employ/api/employ-company-list'
import { scale, financing } from '@/modules/employ/data/index'
import tinymceMixin from '@/modules/employ/mixin/tinymce-mixin'

export default {
  components: { FixedActionBar, AddressSelector, ImageWall },
  mixins: [tinymceMixin],
  data() {
    return {
      financing,
      scale,
      categoryArr: [],
      form: {
        logo: '',
        id: '',
        name: '',
        email: '',
        category: '',
        scale: 0,
        financing: 0,
        addresses: [], // 数组
        content: '',
      },
      loading: false,
      // 校验规则
      rules: {
        name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请输入企业邮箱',

            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: '请输入企业描述',

            trigger: 'blur',
          },
        ],
        category: [
          { required: true, message: '请选择企业行业', trigger: 'change' },
        ],
        // addresses: [
        //   { required: true, message: '请选择企业地址', trigger: 'change' },
        // ],
      },
      addressSelector: {
        show: false,
        index: -1,
        form: {
          id: '',
          lat: '',
          lng: '',
          address: '',
          address_name: '',
        },
      },
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    editAddresses(index, val) {
      if (val) {
        this.form.addresses.splice(index, 1, val)
      } else {
        this.form.addresses.splice(index, 1)
      }
    },
    // 保存配置
    saveConfig() {
      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true

          saveCompany(this.form)
            .then((res) => {
              this.$message.success(res.msg)
              this.loading = false
              this.cancel()
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      this.loading = true
      getCompanyDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          this.form = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取全部企业分类
    getCompanyCategory() {
      getCategories()
        .then((res) => {
          console.log(res)
          this.categoryArr = res.data
        })
        .catch((err) => {})
    },
    // 选择地址
    handleChangeAddress(val) {
      val = {
        name: val.address_name,
        address: val.address,
        lat: val.lat,
        lng: val.lng,
      }
      if (this.addressSelector.index !== -1) {
        this.editAddresses(this.addressSelector.index, {
          ...val,
          id: this.form.addresses[this.addressSelector.index].id,
        })
      } else {
        this.form.addresses.push({
          ...val,
          id: 0,
        })
      }
    },
    // 新增地址
    addAddresses(index) {
      this.addressSelector.show = true
      this.addressSelector.index = index
      if (index === -1) {
        this.addressSelector.form = {
          id: '',
          lat: '',
          lng: '',
          address: '',
          address_name: '',
        }
      } else {
        this.addressSelector.form = {
          id: 0,
          lat: this.form.addresses[index].lat,
          lng: this.form.addresses[index].lng,
          address: this.form.addresses[index].address,
          address_name: this.form.addresses[index].name,
        }
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getDatail()
    }
    this.getCompanyCategory()
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.tag-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 300px;
  .el-tag {
    display: flex;
    align-items: center;
  }
  .tag-text {
    max-width: 345px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
    cursor: pointer;
    display: inline-block;
  }
}
.save-content {
  ::v-deep.el-input {
    width: 300px;
  }
}
</style>
